import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/lesson_start_screen.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('lessonStartScreen', [
    '$injector',
    function factory($injector) {
        const lessonBookendMixin = $injector.get('lessonBookendMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playerViewModel: '<',
            },
            link(scope, elem) {
                lessonBookendMixin(scope, elem);

                scope.$watch('lesson.description', description => {
                    scope.descriptionBullets = [];
                    if (description) {
                        description.forEach(descriptionItem => {
                            // make it an object instead of a string to avoid
                            // an error on possible dupes
                            scope.descriptionBullets.push({
                                text: descriptionItem,
                            });
                        });
                    }
                    if (scope.descriptionBullets.length === 0) {
                        scope.startAnimation();
                    }
                });
            },
        };
    },
]);
