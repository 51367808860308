import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList.Frame.ComponentizedBase', [
    'Lesson.FrameList.Frame',
    'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
    '$injector',

    (Frame, ComponentModel, $injector) => {
        // Support lazy loading of injectables
        Frame.mapInjectables({
            // ui component
            'ComponentizedFrame.UiComponent':
                'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',

            // content providers
            'ComponentizedFrame.Challenges':
                'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesModel',

            // layouts
            'ComponentizedFrame.Layout': 'Lesson.FrameList.Frame.Componentized.Component.Layout.LayoutModel',
            'ComponentizedFrame.TextImageInteractive':
                'Lesson.FrameList.Frame.Componentized.Component.Layout.TextImageInteractive.TextImageInteractiveModel',

            // challenge stuff
            'ComponentizedFrame.AnswerMatcher':
                'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.AnswerMatcherModel',
            'ComponentizedFrame.SimilarToSelectableAnswer':
                'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.SimilarToSelectableAnswer.SimilarToSelectableAnswerModel',
            'ComponentizedFrame.MatchesExpectedText':
                'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.MatchesExpectedText.MatchesExpectedTextModel',
            'ComponentizedFrame.AnswerList':
                'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListModel',
            'ComponentizedFrame.Challenge': 'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeModel',
            'ComponentizedFrame.MultipleChoiceChallenge':
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
            'ComponentizedFrame.UserInputChallenge':
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeModel',
            'ComponentizedFrame.ChallengeOverlayBlank':
                'Lesson.FrameList.Frame.Componentized.Component.ChallengeOverlayBlank.ChallengeOverlayBlankModel',
            'ComponentizedFrame.MultipleChoiceMessage':
                'Lesson.FrameList.Frame.Componentized.Component.MultipleChoiceMessage.MultipleChoiceMessageModel',
            'ComponentizedFrame.UserInputMessage':
                'Lesson.FrameList.Frame.Componentized.Component.UserInputMessage.UserInputMessageModel',
            'ComponentizedFrame.ChallengeValidator':
                'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ChallengeValidatorModel',
            'ComponentizedFrame.SelectableAnswer':
                'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerModel',
            'ComponentizedFrame.TilePrompt':
                'Lesson.FrameList.Frame.Componentized.Component.TilePrompt.TilePromptModel',
            'ComponentizedFrame.MatchingBoard':
                'Lesson.FrameList.Frame.Componentized.Component.MatchingBoard.MatchingBoardModel',
            'ComponentizedFrame.TilePromptBoard':
                'Lesson.FrameList.Frame.Componentized.Component.TilePromptBoard.TilePromptBoardModel',
            'ComponentizedFrame.MatchingChallengeButton':
                'Lesson.FrameList.Frame.Componentized.Component.MatchingChallengeButton.MatchingChallengeButtonModel',
            'ComponentizedFrame.SelectableAnswerNavigator':
                'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerNavigatorModel',

            // continue buttons
            'ComponentizedFrame.ChallengesContinueButton':
                'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ChallengesContinueButton.ChallengesContinueButtonModel',
            'ComponentizedFrame.AlwaysReadyContinueButton':
                'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.AlwaysReadyContinueButton.AlwaysReadyContinueButtonModel',

            // frame navigator
            'ComponentizedFrame.FrameNavigator':
                'Lesson.FrameList.Frame.Componentized.Component.FrameNavigator.FrameNavigatorModel',

            // other stuff
            'ComponentizedFrame.Image': 'Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel',
            'ComponentizedFrame.Text': 'Lesson.FrameList.Frame.Componentized.Component.Text.TextModel',
            'ComponentizedFrame.ComponentOverlay':
                'Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayModel',
            'ComponentizedFrame.InteractiveCards':
                'Lesson.FrameList.Frame.Componentized.Component.InteractiveCards.InteractiveCardsModel',
        });

        // we could get this from the list, but I don't want to try to keep track of all those models
        // in the arguments array.  Easier to pull it this way
        const ContinueButtonModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ContinueButtonModel',
        );
        const FrameNavigatorModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.FrameNavigator.FrameNavigatorModel',
        );
        const ImageModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel');
        const TextImageInteractive = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Layout.TextImageInteractive.TextImageInteractiveModel',
        );
        const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
        const $q = $injector.get('$q');
        const splitOnSpecialBlock = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Text.TextHelpers',
        ).splitOnSpecialBlock;

        const ComponentizedBase = Frame.subclass(function () {
            this.embedsMany('components', 'Lesson.FrameList.Frame.Componentized.Component.ComponentModel');

            // supportedComponents is an array of strings which are names of components that can
            // be added at the top level of the frame. Any other components must be referenced by the treee
            // that starts from the top-level components.
            this.extendableArray('supportedComponents');

            this.extend({
                supportsComponent(componentName, componentType) {
                    this.supportedComponents().push(componentName);

                    const idKey = this._idKeyForSupportedComponent(componentName);

                    Object.defineProperty(this.prototype, componentName, {
                        get() {
                            return this[idKey] && this.dereference(this[idKey]);
                        },
                        set(component) {
                            const existingComponent = this[componentName];
                            if (existingComponent === component) return;
                            if (existingComponent) existingComponent.remove();
                            if (!component) {
                                this[idKey] = undefined;
                            } else if (component.isA && component.isA(componentType)) {
                                this[idKey] = component.id;
                            } else {
                                throw new Error(`Cannot set ${componentName} to an object of the wrong type.`);
                            }
                        },
                    });
                },

                _idKeyForSupportedComponent(componentName) {
                    return `${componentName}Id`.underscore();
                },
            });

            this.supportsComponent('continueButton', ContinueButtonModel);
            this.supportsComponent('frameNavigator', FrameNavigatorModel);

            // NOTE: utilizing around callbacks were creating max callstack issues in Chrome. Perhaps this can eventually be fixed.
            // this.setCallback('before', 'save', 'removeUnreferencedComponents');

            Object.defineProperty(this.prototype, 'miniInstructions', {
                get() {
                    throw new Error('subclasses of ComponentizedBase must implement miniInstructions');
                },
            });

            Object.defineProperty(this.prototype, 'mainTextComponent', {
                get() {
                    throw new Error('subclasses of ComponentizedBase must implement mainTextComponent');
                },
                set() {
                    throw new Error('subclasses of ComponentizedBase must implement mainTextComponent');
                },
                configurable: true, // specs
            });

            // Used outside when the frame interfaces with things in the editor.
            // For switching between componentized and non-componentized
            // frame types and for populating the thumbnails
            Object.defineProperty(this.prototype, 'text_content', {
                get() {
                    return this.mainTextComponent?.text;
                },
                set(val) {
                    // when first initializing one of these by switching from another
                    // frame type, text_content might be set, but we might not have
                    // a mainTextComponent
                    if (this.mainTextComponent) {
                        this.mainTextComponent.text = val;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'mainText', {
                get() {
                    return this.mainTextComponent?.text || null;
                },
            });

            Object.defineProperty(this.prototype, 'mainModalTexts', {
                get() {
                    if (this.mainTextComponent && this.mainTextComponent.editorViewModel) {
                        return this.mainTextComponent.editorViewModel.modalTexts;
                    }
                    return [];
                },
                set(modalTexts) {
                    if (!this.mainTextComponent) {
                        throw new Error('mainModalTexts cannot be set because there is no main text component');
                    }
                    this.mainTextComponent.editorViewModel.modalTexts = modalTexts;
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'label', {
                get() {
                    const truncateTo = 30;
                    const text = this.text_content || '';
                    const truncatedText = text.length > truncateTo ? `${text.substring(0, truncateTo)}...` : text;
                    return [this.index() + 1, ': ', truncatedText].join('');
                },
            });

            // Used outside when the frame interfaces with things in the editor.
            // For switching between componentized and non-componentized
            // frame types and for populating the thumbnails
            Object.defineProperty(this.prototype, 'mainImage', {
                get() {
                    throw new Error('subclasses of ComponentizedBase must implement mainImage');
                },
                set() {
                    throw new Error('subclasses of ComponentizedBase must implement mainImage');
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'mainImageSrc', {
                get() {
                    return this.mainImage?.urlForFormat('original');
                },
            });

            Object.defineProperty(this.prototype, 'imageEditorViewModels', {
                get() {
                    const editorViewModels = [];
                    this.components.forEach(component => {
                        if (component.isA(ImageModel)) {
                            editorViewModels.push(component.editorViewModel);
                        }
                    });
                    return editorViewModels;
                },
            });

            Object.defineProperty(this.prototype, 'imageComponents', {
                get() {
                    const models = [];
                    this.components.forEach(component => {
                        if (component.isA(ImageModel)) {
                            models.push(component);
                        }
                    });
                    return models;
                },
            });

            Object.defineProperty(this.prototype, 'hasBranching', {
                get() {
                    return this.frameNavigator.has_branching || false;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'topLevelComponents', {
                get() {
                    return this.constructor
                        .supportedComponents()
                        .map(componentName => this[componentName])
                        .filter(c => c);
                },
            });

            return {
                buttonDirectiveName: 'componentized-continue-button',

                initialize($super, attrs) {
                    attrs.components = attrs.components || [];
                    $super(attrs);
                    this.$$editorViewModels = {};
                    this.$$_componentMap = {};
                    this.$$_componentsByType = {};
                    if (!this.frameNavigator) {
                        this.frameNavigator = FrameNavigatorModel.EditorViewModel.addComponentTo(this).model;
                    }
                },

                beforeSave() {
                    this.removeUnreferencedComponents();
                },

                // In order to speed things up, cache components in a hash
                // once they have been dereferenced once, so that we don't have
                // to loop through the components array over and over again
                dereference(id) {
                    if (!this.$$_componentMap[id]) {
                        this.components.forEach(component => {
                            if (component.id === id) {
                                this.$$_componentMap[id] = component;
                            }
                        });
                    }

                    if (!this.$$_componentMap[id]) {
                        const err = new Error('No component found for requested id.');
                        $injector.get('ErrorLogService').notify(err, null, {
                            componentId: id,
                        });
                        throw err;
                    }

                    return this.$$_componentMap[id];
                },

                getComponentById(id) {
                    let component;
                    try {
                        component = this.dereference(id);
                    } catch (e) {
                        if (!e.message.match(/No component found/)) {
                            throw e;
                        }
                    }
                    return component;
                },

                mapComponentsById() {
                    const componentMap = {};
                    this.components.forEach(c => {
                        componentMap[c.id] = c;
                    });
                    return componentMap;
                },

                addComponent(component) {
                    if (component.frame()) {
                        throw new Error('Can only add components that are not on any frame');
                    }

                    // do not use this.$$_componentMap, because it may not have everything
                    const componentMap = this.mapComponentsById();
                    const existingComponent = componentMap[component.id];

                    // If a component with this id already exists, skip it.  Theoretically,
                    // this could be dangerous.  Hopefully, it won't be a problem because
                    // we will only hit this if we try to import the same component from another
                    // frame more than once.  In those cases, we want to keep the first import,
                    // since we may have changed it.
                    if (!existingComponent) {
                        component.$$embeddedIn = this;
                        this.components.push(component);
                    }

                    this._onComponentListChanged();
                    return existingComponent || component;
                },

                /*
                    Copy a component from a different frame over to this one.

                    If options.addReferences is true, then all referenced components
                    will also be imported.

                    options.skipReferences is an array of keys, and any component
                    with that key will be skipped (see indirectlyReferencedComponents
                    for that implementation)
                */
                importComponent(component, options = {}) {
                    if (component.frame() === this) {
                        throw new Error('Cannot copy a component onto this frame if it is already on this frame.');
                    }

                    const componentsToAdd = [component];

                    if (options.addReferences) {
                        component.indirectlyReferencedComponents(options).forEach(referencedComponent => {
                            componentsToAdd.push(referencedComponent);
                        });
                    }

                    // eslint-disable-next-line no-shadow
                    const addedComponents = componentsToAdd.map(component => {
                        if (!this.components.includes(component)) {
                            // clone the components so we don't mess with the
                            // source frame
                            const clonedComponent = component.clone();
                            return this.addComponent(clonedComponent);
                        }
                        return undefined;
                    });

                    // addedComponents[0] may be a clone of the component that was
                    // passed in, or it may be the same one.
                    return addedComponents[0];
                },

                // loop through this frame's branching overrides looking for references pointing
                // to the old frame, and replace those references with references to the
                // specified new frame
                replaceFrameReferences(oldFrame, newFrame) {
                    // look for references in answer-specific overrides
                    if (this.frameNavigator && this.frameNavigator.selectableAnswerNavigators) {
                        this.frameNavigator.selectableAnswerNavigators.forEach(selectableAnswerNavigator => {
                            if (selectableAnswerNavigator.next_frame_id === oldFrame.id) {
                                selectableAnswerNavigator.next_frame_id = newFrame.id;
                            }
                        });
                    }

                    // look for references on the frameNavigator
                    if (this.frameNavigator.next_frame_id === oldFrame.id) {
                        this.frameNavigator.next_frame_id = newFrame.id;
                    }
                    return this;
                },
                /*
                    Make it so that any components that used to reference
                    the old component now reference the new one.

                    Both components should already be on the frame.
                */
                swapComponents(oldComponent, newComponent) {
                    if (oldComponent.frame() !== this) {
                        throw new Error('oldComponent is not in this frame');
                    }

                    if (newComponent.frame() !== this) {
                        throw new Error('newComponent is not in this frame');
                    }

                    this.components.forEach(component => {
                        component.editorViewModel.swapReferences(oldComponent, newComponent);
                    });
                },

                /*
                    Copy all the images, the main text content, the modals,
                    and the main image from another frame onto this one. (If this
                    frame does not support a main image then that will not be set).
                */
                copyMainTextAndImagesFrom(otherFrame) {
                    if (!otherFrame) {
                        return;
                    }
                    const frame = this;
                    otherFrame.componentsForType(ImageModel).forEach(image => {
                        frame.importComponent(image);
                    });

                    [
                        // if the editor template set one of these (for example,
                        // multiple_choice_poll sets the mainImageId), do not
                        // override it.  Except with mainModalTexts.  For those, new ones
                        // get created, but they are just defaults.  Copy over the existing
                        // values.  A bit hacky, but it works.
                        'text_content',
                        'mainModalTexts',
                        'mainImage',
                    ].forEach(key => {
                        if (!frame[key] || key === 'mainModalTexts') {
                            frame[key] = otherFrame[key];
                        }
                    });
                },

                editorViewModelFor(model) {
                    return this.editorViewModelsFor([model])[0];
                },

                editorViewModelsFor(models) {
                    const editorViewModels = [];
                    angular.forEach(models, model => {
                        // Note: If you get a max call stack error that traces back to here,
                        // the issue is probably that you are initializing editor view models in the
                        // initialize method of other editor view models in some sort of
                        // circular manner.
                        if (!model || !model.isA || !model.isA(ComponentModel)) {
                            // eslint-disable-next-line no-console
                            console.error('Not a ComponentModel: ', model);
                            throw new Error(
                                `Cannot create EditorViewModel for something which is not a ComponentModel: ${model}`,
                            );
                        }
                        if (model.frame() !== this) {
                            throw new Error('Cannot create EditorViewModel for unrelated component.');
                        }

                        // if we don't have a EditorViewModel yet, and this component supports EditorViewModels, create one
                        if (!this.$$editorViewModels[model.id] && model.constructor.EditorViewModel) {
                            let editorViewModel;
                            try {
                                editorViewModel = new model.constructor.EditorViewModel(model);
                            } catch (err) {
                                if (err.message.match(/Maximum call stack/i)) {
                                    throw new Error(
                                        `The EditorViewModel initializer for ${model.type} raised a Maximum call stack error.  Most likely it referenced model.editorViewModel.  Do not do that.`,
                                    );
                                } else {
                                    throw err;
                                }
                            }
                            this.$$editorViewModels[model.id] = editorViewModel;
                            editorViewModel.applyCurrentTemplate();
                        }
                        editorViewModels.push(this.$$editorViewModels[model.id]);
                    });
                    return editorViewModels;
                },

                editorViewModelsForType(modelKlass) {
                    return this.componentsForType(modelKlass).map(component => this.editorViewModelFor(component));
                },

                componentsForType(modelKlass) {
                    if (typeof modelKlass === 'string') {
                        modelKlass = $injector.get(`Lesson.FrameList.Frame.Componentized.Component.${modelKlass}`);
                    }

                    // cache the results so that we're not looping through
                    // the components again and again.  The cache is blown away
                    // in addComponent
                    if (!this.$$_componentsByType[modelKlass.alias()]) {
                        const components = [];
                        this.components.forEach(component => {
                            if (component.isA(modelKlass)) {
                                components.push(component);
                            }
                        });
                        this.$$_componentsByType[modelKlass.alias()] = components;
                    }
                    return this.$$_componentsByType[modelKlass.alias()];
                },

                removeComponent(component) {
                    // clone the list of components first, so that
                    // if components are removed from within the loop, it
                    // does not cause trouble (see https://trello.com/c/7ZoFhlGK/116-bug-error-when-removing-answer-from-answer-list-in-editor)
                    const components = this.components.slice(0);
                    components.forEach(_component => {
                        this.editorViewModelFor(_component).removeReferencesTo(component);
                    });

                    delete this.$$_componentMap[component.id];
                    this.constructor.supportedComponents().forEach(key => {
                        const topLevelComponent = this[key];
                        if (topLevelComponent === component) {
                            const idKey = this.constructor._idKeyForSupportedComponent(key);
                            delete this[idKey];
                        }
                    });

                    Array.remove(this.components, component);
                    component.$$embeddedIn = undefined;
                    this._onComponentListChanged();
                },

                applyDefaultEditorTemplate() {
                    const defaultTemplate = TextImageInteractive.EditorViewModel.templates.no_interaction;
                    const helper = defaultTemplate.MainUiComponentEditorViewModel.addComponentTo(this).setup();
                    this.mainUiComponent = helper.model;
                    helper.applyTemplate(defaultTemplate);
                },

                /*
                    FIXME: for this to really work, we would need to call it
                    again and again until nothing is removed.  It's possible that
                    the removal of one component can cause others to get removed
                    and leave something unreferenced.  We ran into this problem
                    with this ticket: https://trello.com/c/br4OTced/379-allow-editing-a-larger-chunk-of-text-at-once-in-blanks-and-compose-blanks-modes.
                    But we fixed it in a different way.
                */
                removeUnreferencedComponents() {
                    const referencedComponentsSet = this._referencedComponentsSet();

                    // it doesn't work to remove components as we're looping
                    // through the array, so we save the removed ones for later
                    const componentsToRemove = [];

                    this.components.forEach(component => {
                        if (!referencedComponentsSet[component.id] && !component.allowUnreferenced) {
                            componentsToRemove.push(component);
                        }
                    });

                    componentsToRemove.forEach(component => {
                        component.remove();
                    });
                },

                getReferencedImages() {
                    const referencedComponentsSet = this._referencedComponentsSet();
                    const images = this.componentsForType(ImageModel);
                    const referencedImages = [];
                    images.forEach(image => {
                        if (referencedComponentsSet[image.id]) {
                            referencedImages.push(image);
                        }
                    });
                    return referencedImages;
                },

                prevFrame() {
                    if (this.index() === 0) {
                        return undefined;
                    }

                    // find all possible frames that could have pointed to this frame
                    // and go back to the first in the list
                    let prevFrame;
                    const frames = this.lesson().frames;
                    for (let i = 0; i < frames.length; i++) {
                        const frame = frames[i].reify(); // for some reason, Safari might not have reified prior frames at this point
                        if (frame !== this && frame.frameNavigator.mightNavigateTo(this)) {
                            prevFrame = frame;
                            break;
                        }
                    }
                    if (!prevFrame) {
                        throw new Error(`No frames navigate to ${this.label}`);
                    }
                    return prevFrame;
                },

                preloadImages() {
                    return $q
                        .all(this.topLevelComponents.map(c => c.recursivelyPreloadImages()))
                        .then(results => results.flat());
                },

                recursivelyGetImageUrls() {
                    return $q
                        .all(this.topLevelComponents.map(c => c.recursivelyGetImageUrls()))
                        .then(results => results.flat());
                },

                validateTextLengths() {
                    let valid = true;

                    this.componentsForType(TextModel).forEach(textModel => {
                        const result = textModel.editorViewModel.validateTextLength();
                        if (!result.valid) {
                            valid = false;
                        }
                    });

                    return {
                        valid,
                    };
                },

                getKeyTerms() {
                    const keyTerms = [];

                    function extractKeyTerms(_ignore, match) {
                        // remove brackets
                        let term = match.replace(/[[\]]/g, '');

                        // trim whitespace
                        term = term.trim();

                        keyTerms.push(term);
                    }

                    function extractKeyTermsIfNotInSpecialBlock(textBlock, inSpecialBlock) {
                        if (!inSpecialBlock) {
                            textBlock.replace(/\*\*([^*]+)\*/g, extractKeyTerms);
                        }
                    }

                    const textModels = this.componentsForType(TextModel);

                    // eslint-disable-next-line no-restricted-syntax
                    for (const textModel of textModels) {
                        const text = textModel.text || ''; // guard against undefined text

                        splitOnSpecialBlock(text, false, extractKeyTermsIfNotInSpecialBlock);
                    }

                    return keyTerms;
                },

                containsText(text) {
                    if (text) {
                        const textModels = this.componentsForType(TextModel);

                        // eslint-disable-next-line no-restricted-syntax
                        for (const textModel of textModels) {
                            // TODO: we may want to update this to use plaintext from formatted_text once we do pre-formatting
                            if (textModel.text && textModel.text.toLowerCase().includes(text.toLowerCase())) {
                                return true;
                            }
                        }
                    }

                    return false;
                },

                formatAllText(force) {
                    let textModels = this.componentsForType(TextModel);

                    if (!force) {
                        textModels = _.filter(textModels, {
                            formatted_text: undefined,
                        });
                    }

                    return $q.all(_.chain(textModels).map('editorViewModel').invokeMap('formatText').value());
                },

                addTransN(convertNonMathjax) {
                    const textModels = this.componentsForType(TextModel);
                    _.chain(textModels).map('editorViewModel').invokeMap('addTransN', convertNonMathjax).value();

                    // if we're changing WITHIN MathJax, we need to also potentially fix up the user input models
                    // to make sure that we're not asking users to type something like "\transn{1.234}".
                    //
                    // We also need to find instances where we've changed multiple choice answers to be something
                    // like "\transn{1.234}" and wrap them in MathJax so they display properly.
                    if (!convertNonMathjax) {
                        const UserInputChallengeModel = $injector.get(
                            'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeModel',
                        );
                        const userInputChallengeModels = this.componentsForType(UserInputChallengeModel);
                        _.chain(userInputChallengeModels).map('editorViewModel').invokeMap('removeTransN').value();

                        const MultipleChoiceChallenge = $injector.get(
                            'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
                        );
                        const multipleChoiceChallengeModels = this.componentsForType(MultipleChoiceChallenge);
                        _.chain(multipleChoiceChallengeModels).map('editorViewModel').invokeMap('wrapTransN').value();
                    }
                },

                transformMixedFractions() {
                    const textModels = this.componentsForType(TextModel);

                    _.chain(textModels).map('editorViewModel').invokeMap('transformMixedFractions').value();
                },

                generateTutorBotDescription() {
                    // FIXME. We need to make then new frame types implement this. We probably want to include some base code here that adds the main image and
                    // text, and then allow for extending it with more info like happens in the challenges component
                    throw new Error('subclasses of ComponentizedBase must implement generateTutorBotDescription');
                },

                // In the editor, we want to make sure that
                // editorViewModels are created for every component, since
                // they might have listeners or something that need to be
                // set up.
                initializeEditorViewModels() {
                    this.components.forEach(component => component.editorViewModel);
                },

                _referencedComponentsSet() {
                    const referencedComponentsSet = {};
                    this.constructor.supportedComponents().forEach(key => {
                        const component = this[key];
                        if (!component) {
                            return;
                        }
                        referencedComponentsSet[component.id] = component;

                        component.indirectlyReferencedComponents().forEach(referencedComponent => {
                            referencedComponentsSet[referencedComponent.id] = referencedComponent;
                        });
                    });

                    return referencedComponentsSet;
                },

                _onComponentListChanged() {
                    // kill the map of componentByType, now that the lists
                    // may be invalidated (we could try to just blow away
                    // certain lists for certain types, but it seems like more
                    // trouble than it's worth, since we'd have to worry about
                    // going up the inheritance chain)
                    this.$$_componentsByType = {};
                },
            };
        });

        return ComponentizedBase;
    },
]);
