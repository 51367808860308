window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.grades = window.locales.am.grades || {};window.locales.am.grades.grades = window.locales.am.grades.grades || {};window.locales.am.grades.grades = {...window.locales.am.grades.grades, ...{
    "policy2": "ለተጨማሪ እርዳታ የአካዳሚክ አማካሪ ቡድኑን በ ",
    "faq": "በየጥ",
    "here": "እዚህ",
    "period": ".",
    "coreCurriculum": "ዋና ስርዓተ ትምህርት",
    "exams": "ፈተናዎች",
    "project": "ፕሮጀክት",
    "projects": "ፕሮጀክቶች",
    "other": "ሌሎች መስፈርቶች",
    "specializations": "ስፔሻላይዜሽን",
    "optional": "አማራጭ",
    "electives": "ተመራጮች",
    "refundEligibility": "ተመላሽ ገንዘብ ብቁነት",
    "refund1": "ለእርስዎ እንዲመች ከፕሮግራሙ ለመመረቅ ማጠናቀቅ ያለብዎትን አጠቃላይ የምዘና ብዛት እንዲሁም እስካሁን ያጠናቀቁትን ምዘናዎች ከዚህ በታች ዘርዝረናል። እባክዎን ይገምግሙ ",
    "refund2": "ለተጨማሪ መረጃ። ",
    "sinceBeginning": "በፕሮግራምዎ ውስጥ የኮርስ ስራ ከጀመሩበት ጊዜ ጀምሮ አጠናቀዋል፡-",
    "of": " የ ",
    "requiredAssessments": "አስፈላጊ ግምገማዎች",
    "note1": "ማስታወሻ፡ በምርጫዎች ውስጥ ያሉ SMARTCASEs በተመላሽ ገንዘብ ስሌት ውስጥ አልተካተቱም። ይመልከቱ ",
    "note2": "በስፔሻላይዜሽን ውስጥ ያሉ SMARTCASE እንዴት በተመላሽ ገንዘብ ፖሊሲ ስሌት ውስጥ እንደሚካተቱ ለዝርዝሮች።",
    "refundPolicy": "የተመላሽ ገንዘብ ፖሊሲ",
    "total": "ጠቅላላ፡ ",
    "course": "ኮርስ",
    "completed": "ተጠናቀቀ",
    "score": "ነጥብ",
    "examCourse": "የፈተና ኮርስ",
    "rubricScore": "የሩቢክ ነጥብ",
    "status": "ሁኔታ",
    "title": "ርዕስ",
    "policy3": " ወይም የበለጠ ተማር ",
    "dateCompleted": "የተጠናቀቀበት ቀን",
    "policy1": "የፕሮግራምዎ የውጤት አሰጣጥ ፖሊሲ ከ ሊደረስበት ይችላል። ",
    "resources": "መርጃዎች",
    "refund_eligibility_thresholds": "የተመላሽ ገንዘብ የብቃት ገደቦች",
    "table_heading_assessment_range": "የግምገማ ክልል",
    "table_heading_percent_refunded": "% የትምህርት ክፍያ ተመላሽ ተደርጓል",
    "assessment_range": "{from} ወደ {to}",
    "thresholds_footer_note": "በካታሎግ ውስጥ የተዘረዘሩ ትምህርቶችን፣ ትኩረቶችን፣ ስፔሻሊስቶችን ወይም ፕሮጀክቶችን ከመጀመራቸው በፊት ያቋረጡ ተማሪዎች 100% የትምህርት ክፍያ ተመላሽ ይደረግላቸዋል።",
    "foundationsNote": "በ {title} ወይም በክፍት ኮርሶች የተገኙ ውጤቶች በመጨረሻው የውጤት ስሌትዎ ላይ አይቆጠሩም።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.grades = window.locales.ar.grades || {};window.locales.ar.grades.grades = window.locales.ar.grades.grades || {};window.locales.ar.grades.grades = {...window.locales.ar.grades.grades, ...{
    "policy2": "للحصول على مساعدة إضافية، اتصل بفريق الإرشاد الأكاديمي على ",
    "faq": "التعليمات",
    "here": "هنا",
    "period": ".",
    "coreCurriculum": "المنهج الأساسي",
    "exams": "الامتحانات",
    "project": "مشروع",
    "projects": "المشاريع",
    "other": "متطلبات اخرى",
    "specializations": "التخصصات",
    "optional": "خياري",
    "electives": "الاختيارية",
    "refundEligibility": "أهلية استرداد الأموال",
    "refund1": "ومن أجل راحتك، أدرجنا أدناه إجمالي عدد التقييمات التي يجب عليك إكمالها للتخرج من البرنامج، بالإضافة إلى عدد التقييمات التي أكملتها حتى الآن. يرجى مراجعة ",
    "refund2": "للحصول على معلومات إضافية. ",
    "sinceBeginning": "منذ أن بدأت الدورات الدراسية في برنامجك، لقد أكملت:",
    "of": " ل ",
    "requiredAssessments": "التقييمات المطلوبة",
    "note1": "ملاحظة: لا يتم تضمين SMARTCASE في المواد الاختيارية في حساب استرداد الأموال. انظر ",
    "note2": "للحصول على تفاصيل حول كيفية تضمين SMARTCASEs في التخصصات في حساب سياسة استرداد الأموال.",
    "refundPolicy": "سياسة الاسترجاع",
    "total": "المجموع: ",
    "course": "دورة",
    "completed": "مكتمل",
    "score": "نتيجة",
    "examCourse": "دورة الامتحان",
    "rubricScore": "نقاط التقييم",
    "status": "حالة",
    "title": "عنوان",
    "policy3": " أو معرفة المزيد ",
    "dateCompleted": "تاريخ الانتهاء",
    "policy1": "يمكن الوصول إلى سياسة الدرجات الخاصة ببرنامجك من ",
    "resources": "موارد",
    "refund_eligibility_thresholds": "حدود أهلية استرداد الأموال",
    "table_heading_assessment_range": "نطاق التقييم",
    "table_heading_percent_refunded": "% استرداد الرسوم الدراسية",
    "assessment_range": "{from} إلى {to}",
    "thresholds_footer_note": "سيتم استرداد 100٪ من الرسوم الدراسية للطلاب الذين ينسحبون قبل البدء في أي من الدروس أو التركيزات أو التخصصات أو المشاريع المدرجة في الكتالوج.",
    "foundationsNote": "لا يتم احتساب الدرجات التي حصلت عليها في {title} أو الدورات المفتوحة في حساب نتيجتك النهائية."
}
};window.locales.en = window.locales.en || {};window.locales.en.grades = window.locales.en.grades || {};window.locales.en.grades.grades = window.locales.en.grades.grades || {};window.locales.en.grades.grades = {...window.locales.en.grades.grades, ...{
    "policy1": "Your program’s grading policy can be accessed from ",
    "policy2": "For additional assistance, contact the academic advising team at ",
    "policy3": " or learn more ",

    "faq": "FAQ",
    "resources": "Resources",
    "here": "here",
    "period": ".",

    "coreCurriculum": "Core Curriculum",
    "exams": "Exams",
    "project": "Project",
    "projects": "projects",
    "other": "Other Requirements",
    "specializations": "Specializations",
    "optional": "Optional",
    "electives": "Electives",

    "course": "Course",
    "completed": "Completed",
    "dateCompleted": "Date Completed",
    "score": "Score",
    "examCourse": "Exam Course",
    "rubricScore": "Rubric Score",
    "status": "Status",
    "title": "Title",

    "refundEligibility": "Refund Eligibility",
    "refund1": "For your convenience, we have listed below the total number of assessments that you must complete in order to graduate from the program, as well as the number of assessments you have completed so far. Please review the ",
    "refund2": "for additional information. ",
    "sinceBeginning": "Since beginning coursework in your program, you have completed:",
    "of": " of ",
    "requiredAssessments": "required assessments",
    "note1": "Note: SMARTCASEs in Electives are not included in the refund calculation. See the ",
    "note2": "for details of how SMARTCASEs in Specializations are included in the refund policy calculation.",
    "refundPolicy": "refund policy",
    "total": "Total: ",
    "refund_eligibility_thresholds": "Refund Eligibility Thresholds",
    "table_heading_assessment_range": "Assessment Range",
    "table_heading_percent_refunded": "% Tuition Refunded",
    "assessment_range": "{from} to {to}",
    "thresholds_footer_note": "Students who withdraw prior to beginning any of the lessons, concentrations, specializations, or projects listed in the catalog will be refunded 100% of tuition.",
    "foundationsNote": "Grades earned in {title} or Open Courses do not count toward your final score calculation."
}
};window.locales.es = window.locales.es || {};window.locales.es.grades = window.locales.es.grades || {};window.locales.es.grades.grades = window.locales.es.grades.grades || {};window.locales.es.grades.grades = {...window.locales.es.grades.grades, ...{
    "policy2": "Para obtener ayuda adicional, comuníquese con el equipo de asesoramiento académico en ",
    "faq": "Preguntas más frecuentes",
    "here": "aquí",
    "period": ".",
    "coreCurriculum": "Currículo básico",
    "exams": "Exámenes",
    "project": "Proyecto",
    "projects": "proyectos",
    "other": "Otros requerimientos",
    "specializations": "Especializaciones",
    "optional": "Opcional",
    "electives": "Electivas",
    "refundEligibility": "Elegibilidad de reembolso",
    "refund1": "Para su comodidad, a continuación enumeramos la cantidad total de evaluaciones que debe completar para graduarse del programa, así como la cantidad de evaluaciones que ha completado hasta ahora. Por favor revise el ",
    "refund2": "para informacion adicional. ",
    "sinceBeginning": "Desde que comenzó los cursos en su programa, ha completado:",
    "of": " de ",
    "requiredAssessments": "evaluaciones requeridas",
    "note1": "Nota: SMARTCASE en optativas no se incluyen en el cálculo del reembolso. Ver el ",
    "note2": "para obtener detalles sobre cómo se incluyen SMARTCASE en Especializaciones en el cálculo de la política de reembolso.",
    "refundPolicy": "politica de reembolso",
    "total": "Total: ",
    "course": "Curso",
    "completed": "Terminado",
    "score": "Puntaje",
    "examCourse": "Curso de examen",
    "rubricScore": "Puntuación de la rúbrica",
    "status": "Estado",
    "title": "Título",
    "policy3": " o aprender más ",
    "dateCompleted": "Fecha de finalización",
    "policy1": "Puede acceder a la política de calificaciones de su programa desde ",
    "resources": "Recursos",
    "refund_eligibility_thresholds": "Umbrales de elegibilidad para reembolso",
    "table_heading_assessment_range": "Rango de evaluación",
    "table_heading_percent_refunded": "% Matrícula reembolsada",
    "assessment_range": "{from} hasta {to}",
    "thresholds_footer_note": "A los estudiantes que se retiren antes de comenzar cualquiera de las lecciones, concentraciones, especializaciones o proyectos enumerados en el catálogo se les reembolsará el 100% de la matrícula.",
    "foundationsNote": "Las calificaciones obtenidas en {title} o cursos abiertos no cuentan para el cálculo de su puntaje final."
}
};window.locales.it = window.locales.it || {};window.locales.it.grades = window.locales.it.grades || {};window.locales.it.grades.grades = window.locales.it.grades.grades || {};window.locales.it.grades.grades = {...window.locales.it.grades.grades, ...{
    "policy2": "Per ulteriore assistenza, contattare il team di consulenza accademica all'indirizzo ",
    "faq": "FAQ",
    "here": "Qui",
    "period": ".",
    "coreCurriculum": "Curriculum di base",
    "exams": "Esami",
    "project": "Progetto",
    "projects": "progetti",
    "other": "Altri requisiti",
    "specializations": "Specializzazioni",
    "optional": "Opzionale",
    "electives": "Elettivi",
    "refundEligibility": "Idoneità al rimborso",
    "refund1": "Per tua comodità, abbiamo elencato di seguito il numero totale di valutazioni che devi completare per diplomarti al programma, nonché il numero di valutazioni che hai completato finora. Si prega di rivedere il ",
    "refund2": "per ulteriori informazioni. ",
    "sinceBeginning": "Dall'inizio dei corsi nel tuo programma, hai completato:",
    "of": " Di ",
    "requiredAssessments": "valutazioni richieste",
    "note1": "Nota: gli SMARTCASE negli Elective non sono inclusi nel calcolo del rimborso. Vedi il ",
    "note2": "per i dettagli su come gli SMARTCASE nelle Specializzazioni sono inclusi nel calcolo della politica di rimborso.",
    "refundPolicy": "politica di rimborso",
    "total": "Totale: ",
    "course": "Corso",
    "completed": "Completato",
    "score": "Punto",
    "examCourse": "Corso d'esame",
    "rubricScore": "Punteggio della rubrica",
    "status": "Stato",
    "title": "Titolo",
    "policy3": " o saperne di più ",
    "dateCompleted": "Data completata",
    "policy1": "È possibile accedere alla politica di valutazione del tuo programma da ",
    "resources": "Risorse",
    "refund_eligibility_thresholds": "Soglie di ammissibilità al rimborso",
    "table_heading_assessment_range": "Intervallo di valutazione",
    "table_heading_percent_refunded": "% Tasse rimborsate",
    "assessment_range": "{from} a {to}",
    "thresholds_footer_note": "Agli studenti che si ritirano prima dell'inizio di una qualsiasi delle lezioni, concentrazioni, specializzazioni o progetti elencati nel catalogo verrà rimborsato il 100% della quota di iscrizione.",
    "foundationsNote": "I voti ottenuti in {title} o nei corsi aperti non vengono conteggiati nel calcolo del punteggio finale."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.grades = window.locales.zh.grades || {};window.locales.zh.grades.grades = window.locales.zh.grades.grades || {};window.locales.zh.grades.grades = {...window.locales.zh.grades.grades, ...{
    "policy2": "如需更多帮助，请联系学术咨询团队 ",
    "faq": "常问问题",
    "here": "这里",
    "period": "。",
    "coreCurriculum": "核心课程",
    "exams": "考试",
    "project": "项目",
    "projects": "项目",
    "other": "其他需求",
    "specializations": "专业化",
    "optional": "选修的",
    "electives": "选修课",
    "refundEligibility": "退款资格",
    "refund1": "为了方便起见，我们在下面列出了您毕业前必须完成的评估总数，以及您迄今为止已完成的评估数量。请查看 ",
    "refund2": "了解更多信息。 ",
    "sinceBeginning": "自开始学习课程以来，您已完成：",
    "of": " 的 ",
    "requiredAssessments": "必修评估",
    "note1": "注意：选修课中的 SMARTCASE 不包含在退款计算中。请参阅 ",
    "note2": "了解有关专业化中的 SMARTCASE 如何纳入退款政策计算的详细信息。",
    "refundPolicy": "退款政策",
    "total": "全部的： ",
    "course": "课程",
    "completed": "完全的",
    "score": "分数",
    "examCourse": "考试课程",
    "rubricScore": "评分标准",
    "status": "地位",
    "title": "标题",
    "policy3": " 或了解更多 ",
    "dateCompleted": "完成日期",
    "policy1": "您的课程评分政策可从以下网址查看 ",
    "resources": "资源",
    "refund_eligibility_thresholds": "退款资格门槛",
    "table_heading_assessment_range": "评估范围",
    "table_heading_percent_refunded": "学费退还百分比",
    "assessment_range": "{from}至{to}",
    "thresholds_footer_note": "在开始目录中列出的任何课程、专业、专业或项目之前退学的学生将获得 100％ 的学费退还。",
    "foundationsNote": "{title}或开放课程所获的成绩不会计入您的最终成绩计算。"
}
};