window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.choose_institution = window.locales.am.choose_institution || {};window.locales.am.choose_institution.choose_institution = window.locales.am.choose_institution.choose_institution || {};window.locales.am.choose_institution.choose_institution = {...window.locales.am.choose_institution.choose_institution, ...{
    "choose_your_institution": "ተቋምዎን ይምረጡ",
    "degree_heading_quantic": "MBA እና አስፈፃሚ MBA",
    "degree_heading_valar": "MBA እና አስፈፃሚ MBA",
    "description_quantic_1": "ኳንቲክ ከጠንካራ ግን ተለዋዋጭ {degreeDurationMonths} -ወር ሥርዓተ-ትምህርት ጋር በጣም የተመረጠ ነው።",
    "description_quantic_2": "በአለም አቀፍ ደረጃ ባለው አለምአቀፍ የምሩቃን አውታረመረብ ውስጥ ከታዋቂ ኩባንያዎች ተጽዕኖ ፈጣሪ መሪዎችን ይቀላቀሉ።",
    "description_valar_1": "ቫላር ተደራሽነትን በይበልጥ በተመጣጣኝ የ {degreeDurationMonths} -ወር የዲግሪ መርሃ ግብሮች ዲሞክራሲያዊ ያደርገዋል።",
    "description_valar_2": "በውስጡ የበለጠ አካታች ቅበላ ትልልቅ እና የተለያዩ አለም አቀፍ የሥልጣን ጥመኞችን ያዳብራል።",
    "apply_now": "አሁን ያመልክቱ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.choose_institution = window.locales.ar.choose_institution || {};window.locales.ar.choose_institution.choose_institution = window.locales.ar.choose_institution.choose_institution || {};window.locales.ar.choose_institution.choose_institution = {...window.locales.ar.choose_institution.choose_institution, ...{
    "choose_your_institution": "إختر مؤسستك",
    "degree_heading_quantic": "ماجستير إدارة الأعمال وماجستير إدارة الأعمال التنفيذي",
    "degree_heading_valar": "ماجستير إدارة الأعمال وماجستير إدارة الأعمال التنفيذي",
    "description_quantic_1": "تتميز Quantic بانتقائيتها العالية ومنهجها الدراسي الصارم والمرن الذي يمتد على مدار {degreeDurationMonths} شهرًا.",
    "description_quantic_2": "انضم إلى القادة المؤثرين من الشركات الشهيرة في شبكة خريجيها العالمية ذات المستوى العالمي.",
    "description_valar_1": "تعمل شركة فالار على إضفاء الطابع الديمقراطي على الوصول من خلال برامج الحصول على درجات علمية أكثر بأسعار معقولة لمدة {degreeDurationMonths} شهرًا.",
    "description_valar_2": "إن قبولها بشكل أكثر شمولاً يعمل على تنمية مجموعات عالمية أكبر وأكثر تنوعاً من القادة الطموحين.",
    "apply_now": "تقدم الآن"
}
};window.locales.en = window.locales.en || {};window.locales.en.choose_institution = window.locales.en.choose_institution || {};window.locales.en.choose_institution.choose_institution = window.locales.en.choose_institution.choose_institution || {};window.locales.en.choose_institution.choose_institution = {...window.locales.en.choose_institution.choose_institution, ...{
    "choose_your_institution": "Choose Your Institution",
    "degree_heading_quantic": "MBA and Executive MBA",
    "degree_heading_valar": "MBA and Executive MBA",
    "description_quantic_1": "Quantic is highly selective with a rigorous yet flexible {degreeDurationMonths}-month curriculum.",
    "description_quantic_2": "Join influential leaders from renowned companies in its world-class global alumni network.",
    "description_valar_1": "Valar democratizes access with more affordable {degreeDurationMonths}-month degree programs.",
    "description_valar_2": "Its more inclusive admissions cultivate larger, diverse global cohorts of ambitious leaders.",
    "apply_now": "Apply Now"
}
};window.locales.es = window.locales.es || {};window.locales.es.choose_institution = window.locales.es.choose_institution || {};window.locales.es.choose_institution.choose_institution = window.locales.es.choose_institution.choose_institution || {};window.locales.es.choose_institution.choose_institution = {...window.locales.es.choose_institution.choose_institution, ...{
    "choose_your_institution": "Elige tu institución",
    "degree_heading_quantic": "MBA y MBA Ejecutivo",
    "degree_heading_valar": "MBA y MBA Ejecutivo",
    "description_quantic_1": "Quantic es altamente selectivo con un plan de estudios riguroso pero flexible de {degreeDurationMonths} meses.",
    "description_quantic_2": "Únase a líderes influyentes de empresas reconocidas en su red global de ex alumnos de clase mundial.",
    "description_valar_1": "Valar democratiza el acceso con programas de grado de {degreeDurationMonths} meses más asequibles.",
    "description_valar_2": "Sus admisiones más inclusivas cultivan cohortes globales más grandes y diversas de líderes ambiciosos.",
    "apply_now": "Aplicar ahora"
}
};window.locales.it = window.locales.it || {};window.locales.it.choose_institution = window.locales.it.choose_institution || {};window.locales.it.choose_institution.choose_institution = window.locales.it.choose_institution.choose_institution || {};window.locales.it.choose_institution.choose_institution = {...window.locales.it.choose_institution.choose_institution, ...{
    "choose_your_institution": "Scegli la tua istituzione",
    "degree_heading_quantic": "MBA ed Executive MBA",
    "degree_heading_valar": "MBA ed Executive MBA",
    "description_quantic_1": "Quantic è altamente selettiva e propone un programma di studi rigoroso ma flessibile della durata di {degreeDurationMonths} mesi.",
    "description_quantic_2": "Unisciti ai leader influenti di aziende rinomate nella nostra rete globale di ex studenti di fama mondiale.",
    "description_valar_1": "Valar democratizza l'accesso con programmi di laurea della durata di {degreeDurationMonths} mesi più convenienti.",
    "description_valar_2": "Le sue ammissioni più inclusive coltivano gruppi globali più ampi e diversificati di leader ambiziosi.",
    "apply_now": "Applica ora"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.choose_institution = window.locales.zh.choose_institution || {};window.locales.zh.choose_institution.choose_institution = window.locales.zh.choose_institution.choose_institution || {};window.locales.zh.choose_institution.choose_institution = {...window.locales.zh.choose_institution.choose_institution, ...{
    "choose_your_institution": "选择您的机构",
    "degree_heading_quantic": "工商管理硕士 (MBA) 和高级工商管理硕士 (EMBA)",
    "degree_heading_valar": "工商管理硕士 (MBA) 和高级工商管理硕士 (EMBA)",
    "description_quantic_1": "Quantic 的选拔非常严格，采用{degreeDurationMonths}个月的课程，但课程设置却非常灵活。",
    "description_quantic_2": "加入其世界一流的全球校友网络中来自知名公司的有影响力的领导者。",
    "description_valar_1": "Valar 提供更实惠的{degreeDurationMonths}个月学位课程，让入学机会更加民主。",
    "description_valar_2": "其更具包容性的招生政策将培养出更庞大、更多样化的全球雄心勃勃的领导者群体。",
    "apply_now": "立即申请"
}
};