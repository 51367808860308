import { extend, has, omitBy } from 'lodash/fp';

/*
    The things in the file are tested in lesson_progress_spec.js.  I didn't
    bother moving those specs over to here because I suspect this will be going
    away soon in favor of a switch to optimistic locking.
*/

// This is kind of the opposite of the server-side merge_challenge_scores
// method.  Here, we are defending against the situation where we send out
// a lesson progress save call and then answer some challenges before the call
// returns.  If we're not careful, the return value will blow away our changes
// to the challenge_scores object.
//
// Unimportant edge case: I guess theoretically you could
// still lose the updates that someone makes to a challenge.
// So, if a save call went out, then the user went back to a previous frame and corrected
// some challenges they had gotten wrong before, those new scores could be blown
// away when the save call returned.  This is irrelevant to test lessons though,
// since users can't go back in that case, and that's where scores really matter.
function mergeInLocalChallengeScores(lessonProgress, incomingChallengeScores) {
    Object.keys(lessonProgress.challenge_scores).forEach(key => {
        const score = lessonProgress.challenge_scores[key];
        if (!has(key)(incomingChallengeScores)) {
            incomingChallengeScores[key] = score;
        }
    });
}

function mergeInLocalCompletedFrames(lessonProgress, incomingCompletedFrames) {
    const merged = extend(incomingCompletedFrames, lessonProgress.completed_frames);
    Object.keys(merged).forEach(key => {
        incomingCompletedFrames[key] = merged[key];
    });
}

function reconcileChallengeScores(lessonProgress, incomingChallengeScores) {
    // Important note: For assessments, we toss out any challenge_scores the server gives us that we
    // don't have in the client object. This is to guard against slow network requests when restarting
    // assessment lessons, as we don't want to merge in a bunch of challenge_scores that should not be there.
    if (lessonProgress.for_assessment_lesson) {
        const localScoreKeys = Object.keys(lessonProgress.challenge_scores);
        incomingChallengeScores = omitBy((_value, key) => !localScoreKeys.includes(key))(incomingChallengeScores);
    }
    return incomingChallengeScores;
}

function reconcileCompletedFrames(lessonProgress, incomingCompletedFrames) {
    // Similar to above and for the same reasons, we omitBy any completed_frames returned by the server that
    // the client object does not have for assessment lessons.
    if (lessonProgress.for_assessment_lesson) {
        const localFrameKeys = Object.keys(lessonProgress.completed_frames);
        incomingCompletedFrames = omitBy((_value, key) => !localFrameKeys.includes(key))(incomingCompletedFrames);
    }
    return incomingCompletedFrames;
}

export default function mergeIncomingChanges(lessonProgress, incomingAttrs) {
    incomingAttrs = {
        ...incomingAttrs,

        // We want to replicate what the server does by making sure to never set
        // `complete` or `completed_at` from a truthy value back to a falsey value.
        complete: lessonProgress.complete || incomingAttrs.complete,
        completed_at: lessonProgress.completed_at || incomingAttrs.completed_at,
        // Now that everyone is on strict scoring, we should always honor the best_score
        // from the server for tests and assessments
        best_score:
            (lessonProgress.for_test_lesson || lessonProgress.for_assessment_lesson) && !!incomingAttrs.best_score
                ? incomingAttrs.best_score
                : // Otherwise, still make sure to use the incoming best score if the local one is falsey
                  lessonProgress.best_score || incomingAttrs.best_score,
    };

    // The reconcile methods only apply to assessments.  They remove any incoming
    // scores or frames that we do not have locally. (NOTE: this actually means that
    // we're not merging in completed frames stuff at all for assessment lessons.  This is
    // not a big deal, and we're likely moving away from this stuff soon in favor
    // of optimistic locking anyway.)
    incomingAttrs.challenge_scores = reconcileChallengeScores(lessonProgress, incomingAttrs.challenge_scores);
    incomingAttrs.completed_frames = reconcileCompletedFrames(lessonProgress, incomingAttrs.completed_frames);

    // The merge methods apply in all cases.  They add any values that exist locally
    // that do NOT exist in the incoming attrs.  If values exist in the incoming attrs,
    // we keep them.
    mergeInLocalChallengeScores(lessonProgress, incomingAttrs.challenge_scores);
    mergeInLocalCompletedFrames(lessonProgress, incomingAttrs.completed_frames);

    return incomingAttrs;
}
