import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    a MatchingBoard is a ui component associated with a Challenges Component
    that uses the "matching" template.  It is placed in the sharedContentForImage
    section in the template.

    It renders a list of challenges and a sharedAnswerList
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.MatchingBoard.MatchingBoardModel', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.MatchingBoard.MatchingBoardViewModel',
    (UiComponentModel, MatchingBoardViewModel) =>
        UiComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.MatchingBoard');
            this.extend({
                ViewModel: MatchingBoardViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.MatchingBoard.MatchingBoardEditorViewModel',
            );

            this.references('matchingChallengeButtons').through('matching_challenge_button_ids');
            this.references('answerList').through('answer_list_id');
            this.references('challengesComponent').through('challenges_component_id');

            return {
                buttonForChallenge(challenge) {
                    return this.matchingChallengeButtons.find(b => b.challenge === challenge);
                },
            };
        }),
]);
